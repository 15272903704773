import axios from 'axios';

function getTools(){
    return axios({
        method: 'get',
        url: '/api/public/boomTools.php'})
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});

}



export default{
    getTools
}