<template>
<div>
  <Header class="mb-4" />

<div class="my-5 mx-auto col-sm-12	col-md-12	col-lg-8 col-xl-8 banner overflow-hidden">

    <div class="row">
        <div class="col-12 d-flex flex-wrap">
          
            <div :key="key" v-for="(tool,key) in boomTools" class="mx-auto mt-3 col-12 col-md-4 d-inline mt-5 ">
              
                <div class="clickable"  v-on:click="setToolImage">
                  
                    <img :data-image="tool.Image"  class="learning-goal-image hover-image clickable" :src="tool.Image" width="100%" /> 
                  
                </div>
                <div class="learning-goal-title align-text-bottom text-center mt-1"> {{tool.Title}} </div>
                <div class="learning-goal-text text-center mt-4">
                   <span class="learning-goal-text"> {{tool.Description}} </span> 
                </div>
              
            </div>
          
        </div>
    </div>


</div>

<vue-final-modal v-model="showModal" :overlay-style="overlayStyle">
  <div class="row mt-2"> 
        <div class="col-md-9 col-10">
        </div>
        <div class="col-md-1 col-1">
            <div v-on:click="showModal=false" class="clickable"> 
                <img src="@/assets/tache.svg" class="remove-x">
            </div>
        </div>
    </div>
    <div class="row">
            <div class="mx-auto col-md-8 col-12  text-center">
            <img class="w-100"  :src="toolImage" >
        </div>
    </div>
</vue-final-modal>
    
   


  <Footer/>
</div>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import axios from 'axios'
import {  VueFinalModal } from 'vue-final-modal'

import BoomToolsService from '@/service/superTools.js'


  export default {
     components: {
       Header,
       Footer,
       VueFinalModal
  },
    data() {
      return {
            src:'/banner_normal/index.html',
            animation:'',
            workshops:[],
        isLoged:false,
        toolImage:"",
        showModal:false,
        boomTools:[],
        tool:{
          Id:'',
          Title:'',
          Description:'',
          Image:{
            file:'',
            name:'',
          }
        },
        overlayStyle:{
          background:"#FFFFFF",
          opacity:0.9,
          overflow:"visible"
        },
      }
    },

    mounted(){
      window.scrollTo(0,0);
      this.checkLogin();
      this.getBanner();   
      this.getTools();
    },

        

    methods:{
    getTools(){
        BoomToolsService.getTools().then(res => { 
          this.boomTools = { ...res.data };
          console.log(this.boomTools)
        });

    },

    setToolImage(e){
        this.toolImage= e.target.dataset.image;
        console.log(this.toolImage)
        this.showModal=true;
    },
    
    
    setToolInfo(toolInfo){
        this.tool.Title = toolInfo.Title;
        this.tool.Image.name = toolInfo.Image;
        this.tool.Image.file = toolInfo.Image;
        this.tool.Description = toolInfo.Description;
        this.tool.Id = toolInfo.Id;
        this.showModal=true;
        this.editTool=true;
    },

    getWorkshops(){

       return axios({
        method: 'get',
        url: '/api/public/workshop.php'})
        .then(res => { 
            this.workshops = res.data;
            console.log(this.workshops)
        });

    },
  
    getBanner(){

    return axios({
        method: 'get',
        url: '/banner_normal/index.html'})
    .then(res => { 
        this.animation = res.data;
        
    });
      },
      checkLogin(){
        if (sessionStorage.getItem("token")!= undefined && sessionStorage.getItem("token")!= "") {
          this.isLoged = true;
        }
      },


      logout(){
        sessionStorage.clear();
        this.isLoged = false;
      },

       getImage(key){
           return require('@/assets/workshops/' +this.workshops[key].Image);

      }
      
    }
  }
</script>

